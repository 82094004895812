import React, { Component } from 'react';
import { PaymentRequestButtonElement, injectStripe } from 'react-stripe-elements';
import { SERVER_NAME } from '../Utils/Constants'
import axios from 'axios';

class PaymentRequestForm extends React.Component {
    constructor(props) {
      super(props);

 
  
      // For full documentation of the available paymentRequest options, see:
      // https://stripe.com/docs/stripe.js#the-payment-request-object
      const paymentRequest = props.stripe.paymentRequest({
        country: 'AU',
        currency: 'aud',
        total: {
          label: props.product.Name,
          amount: props.product.retailPrice * 100,
        },
      });
  
      paymentRequest.on('token', ({complete, token, ...data}) => {
        console.log('Received Stripe token: ', token);
        console.log('Received customer information: ', data);
        var transaction = {
          DateTimeTrans: "2019-03-28T12:02:09.796Z",
          OrderId:  Math.floor(Math.random() * (1000 - 1) + 1),
          OrderComment: "self-ordering",
          Channel: "API_BNE_MGF_APP",
          items: [
            {
              productId: this.props.product.productid,
              QtySold: "1",
              size: "0",
              Gross: this.props.product.retailPrice,
              Nett: this.props.product.retailPrice
            }

          ],
          payments: [
            {
              PaymentName: "Apple Pay",
              PaymentAmount: this.props.product.retailPrice,
              PaymentReference: token.id
            }
          ]

        }

        axios.post(SERVER_NAME + `/rest/zzDamien.pocAPI.createTransaction`,  {transaction})
        .then(res => {
          console.log(res.data);
          if (res.status == 200) {
            this.setState({ complete: true, reference: res.data.response.reference });
            complete('success');
          }
        })
       
      });
  
      paymentRequest.canMakePayment().then((result) => {
        this.setState({canMakePayment: !!result});
      });
  
      this.state = {
        canMakePayment: false,
        complete: false,
        reference: null,
        paymentRequest,
      };
    }
  
    render() {
      if (this.state.complete) return <h2>Purchase Complete! reference: {this.state.reference}</h2>;

      return this.state.canMakePayment ? (
        <PaymentRequestButtonElement
          paymentRequest={this.state.paymentRequest}
          className="PaymentRequestButton"
          style={{
            // For more details on how to style the Payment Request Button, see:
            // https://stripe.com/docs/elements/payment-request-button#styling-the-element
            paymentRequestButton: {
              theme: 'dark',
              height: '64px',
            },
          }}
        />
      ) : null;
    }
  }
  export default injectStripe(PaymentRequestForm);