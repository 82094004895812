import React, { Component } from 'react';
// import './App.css';
import OrderItem from './Components/OrderItem';
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import CardGroup from 'react-bootstrap/CardGroup'
import CardColumns from 'react-bootstrap/CardColumns'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Payment from './Components/Payment' 
import { SERVER_NAME} from './Utils/Constants'
import Charge from './Components/Charge'
import axios from 'axios';


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allItems: [],
    };
  }

  componentDidMount() {
    axios.get(SERVER_NAME + `/rest/zzDamien.pocAPI.getItems`)
      .then(res => {
       // console.log(res.data)
        const allItems = res.data.items.results;
        this.setState({ allItems });
      })
  }

  render() {

    const listItems = this.state.allItems.map((item) => {

      return <OrderItem product={item} key={item.productid}></OrderItem>

    }

    );


    return (
      <div className="App">
        <Container>
          <CardColumns>
            {listItems}
          </CardColumns>
        </Container>
      </div>
    );
  }
}

function App() {
  return (
    <Router>
      <div>
        <Header />

        <Route exact path="/" component={Home} />
        {/* <Route exact path="/home" component={Home} /> */}
        <Route path="/checkOut" component={Payment} />
        <Route path="/Charge" component={Charge} />
      </div>
    </Router>
  );
}


function Header() {
  return (
    <Navbar bg="light" expand="lg">
    <Link to="/"> <Navbar.Brand href="#home">Self Ordering</Navbar.Brand></Link>
  </Navbar>
  );
}

export default App;
