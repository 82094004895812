import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { Route } from "react-router-dom";
import Image from 'react-bootstrap/Image'
import QRCode from 'qrcode.react'
import { SERVER_NAME } from '../Utils/Constants'
import axios from 'axios';

var MAX_POLL_COUNT = 30;
var pollCount = 0;

class Wechatpay extends React.Component {
    state = {
        location: null,
       complete: false, 
       reference: null

      };
    constructor(props) {
      super(props);
      this.timer = null;

     
    }

    // After some amount of time, we should stop trying to resolve the order synchronously:

    
  pollForSourceStatus(sourceID, clientSecret) {
      this.props.stripe.retrieveSource({id: sourceID, client_secret: clientSecret}).then(result => {
        var source = result.source;
        
        console.log(result)
        if (source.status === 'chargeable') {
          // Make a request to your server to charge the Source.
          // Depending on the Charge status, show your customer the relevant message.
          console.log('pollForSourceStatus')
          clearTimeout(this.timer);

          var transaction = {
            DateTimeTrans: (new Date()).toISOString(),
            OrderId:  Math.floor(Math.random() * (1000 - 1) + 1),
            OrderComment: "self-ordering",
            Channel: "API_BNE_MGF_APP",
            items: [
              {
                productId: this.props.product.productid,
                QtySold: "1",
                size: "0",
                Gross: this.props.product.retailPrice,
                Nett: this.props.product.retailPrice
              }

            ],
            payments: [
              {
                PaymentName: "Stripe Card",
                PaymentAmount: this.props.product.retailPrice,
                PaymentReference: sourceID
              }
            ]

          }

          console.log(JSON.stringify(transaction));
          axios.post(SERVER_NAME + `/rest/zzDamien.pocAPI.createTransaction`,  {transaction})
            .then(res => {
              console.log(res.data);
              if (res.status == 200) {
                this.setState({ complete: true, reference: res.data.response.reference });
              }
            })

        

        } else if (source.status === 'pending' && pollCount < MAX_POLL_COUNT) {
          // Try again in a second, if the Source is still `pending`:
          pollCount += 1;
          this.timer = setTimeout(() => {
            this.pollForSourceStatus(sourceID, clientSecret)
          }, 1000)
        } else {
          // Depending on the Source status, show your customer the relevant message.
        }
      });
    }
    componentDidMount(){

      this.props.stripe.createSource({
        type: 'wechat',
        amount: this.props.product.retailPrice * 100,
        currency: 'aud',
        redirect: {
          return_url: 'https://localhost:3000/',
        },
      }).then((result) => {
        console.log(result)
        console.log(result.source.wechat.qr_code_url)
        this.pollForSourceStatus(result.source.id, result.source.client_secret);
    ;
    this.setState({
            location: result.source.wechat.qr_code_url,
           
          });
       

      });


    }
 
    
    render() {
      console.log(this.state.location)
      if (this.state.complete) return <h2>Purchase Complete! reference: {this.state.reference}</h2>;
      return this.state.location ? <div><QRCode value={this.state.location} /></div>:   (<section>Loading...</section>);
    }
  }
  export default injectStripe(Wechatpay);
  //