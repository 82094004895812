import React, { Component } from 'react';
import { injectStripe } from 'react-stripe-elements';
import { Route } from "react-router-dom";

class Charge extends React.Component {
    state = {
        location: null,
       
      };
    constructor(props) {
      super(props);
      var that = this
      props.stripe.createSource({
        type: 'alipay',
        amount: props.product.retailPrice * 100,
        currency: 'aud',
        redirect: {
          return_url: 'http://localhost:3000/charge',
        },
      }).then(function(result) {
        console.dir(result)
        console.log(result.source.redirect.url)
        
        that.state = {
            location: result.source.redirect.url,
           
          };
          window.location = result.source.redirect.url

      });

     
    }
 
  
    render() {
      return this.state.location ? null:   (<section>Redirecting...</section>);
    }
  }
  export default Charge;