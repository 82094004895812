import React, { Component } from 'react';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { Link } from "react-router-dom";
import StripeCheckout from 'react-stripe-checkout';
import Checkout from './Checkout'
import Payment from './Payment'


class OrderItem extends Component {
  render() {
//    console.log(this.props)


    return (


      <Card style={{ width: '16rem' }} >
        <Card.Img variant="top" src={this.props.product.imageUrl}   />
        <Card.Body>
          <Card.Title>{this.props.product.Name}</Card.Title>
          <Card.Text>
            ${this.props.product.retailPrice}
    </Card.Text>
         <Link to={{ 
  pathname: '/checkout', 
  state: {
    product: this.props.product
  } 
}} > <Button variant="primary">Order</Button></Link>
         {/* <Checkout product={this.props.product} /> */}
        </Card.Body>
      </Card>

    );
  }
}

export default OrderItem;
