import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';
import Container from 'react-bootstrap/Container'
import { Card, Tab, Tabs } from 'react-bootstrap'
import PaymentRequestForm from './PaymentRequestForm';
import Alipay from './Alipay'
import WeChatPay from './Wechatpay'

class Payment extends Component {



  constructor() {
    super();
    this.state = {
      elementFontSize: window.innerWidth < 450 ? '12px' : '18px',
      key: 'card',
    };
    window.addEventListener('resize', () => {
      if (window.innerWidth < 450 && this.state.elementFontSize !== '14px') {
        this.setState({ elementFontSize: '14px' });
      } else if (
        window.innerWidth >= 450 &&
        this.state.elementFontSize !== '18px'
      ) {
        this.setState({ elementFontSize: '18px' });
      }
    });
  }

  render() {
    const { elementFontSize } = this.state;
    //console.dir(this.props.location)

    const alipayContent = this.state.key === 'alipay' ? (<StripeProvider apiKey="pk_test_u1g83gXjfKIxjV1uzgCeLtgM00EkfDjZK0">

      <Elements>


        <Alipay product={this.props.location.state.product} />
      </Elements>

    </StripeProvider>) : null;

const wechatpayContent = this.state.key === 'wechatpay' ? (<StripeProvider apiKey="pk_test_u1g83gXjfKIxjV1uzgCeLtgM00EkfDjZK0">

<Elements>


  <WeChatPay product={this.props.location.state.product} />
</Elements>

</StripeProvider>) : null;
console.log(alipayContent)
    return (

      <Container>

        <Card style={{ width: '19rem' }} >

          <Card.Body>
            <Card.Title>Check out</Card.Title>

            <Tabs id="uncontrolled-tab-example" 
              activeKey={this.state.key}
              onSelect={key => this.setState({ key })}>

              <Tab eventKey="card" title="Card">


                <StripeProvider apiKey="pk_test_u1g83gXjfKIxjV1uzgCeLtgM00EkfDjZK0">

                  <Elements>

                    <CheckoutForm fontSize={elementFontSize} product={this.props.location.state.product} />

                  </Elements>

                </StripeProvider>
                <StripeProvider apiKey="pk_test_u1g83gXjfKIxjV1uzgCeLtgM00EkfDjZK0">

                  <Elements>


                    <PaymentRequestForm product={this.props.location.state.product} />
                  </Elements>

                </StripeProvider>


              </Tab>
              <Tab eventKey="alipay" title="Alipay">
                {alipayContent}
  
              </Tab>
              <Tab eventKey="wechatpay" title="WeChat Pay" >
              {wechatpayContent}

              </Tab>
            </Tabs>

          </Card.Body>
        </Card>
      </Container>


    );
  }
}

export default Payment;