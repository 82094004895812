import React, { Component } from 'react';
import { CardElement, injectStripe, InjectedProps } from 'react-stripe-elements';
import Button from 'react-bootstrap/Button';
import styles from './CheckoutForm.module.css'
import { SERVER_NAME } from '../Utils/Constants'
import axios from 'axios';


const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = { complete: false, reference: null };
  }


  handleSubmitLAN = (ev) => {
    ev.preventDefault();
  

          var transaction = {
            DateTimeTrans: (new Date()).toISOString(),
            OrderId:  Math.floor(Math.random() * (1000 - 1) + 1),
            OrderComment: "self-ordering",
            Channel: "API_BNE_MGF_APP",
            items: [
              {
                productId: this.props.product.productid + '',
                QtySold: "1",
                size: "0",
                Gross: this.props.product.retailPrice + '',
                Nett: this.props.product.retailPrice + ''
              }

            ],
            payments: [
              {
                PaymentName: "Stripe Card",
                PaymentAmount: this.props.product.retailPrice + '',
                PaymentReference: "token-1234"
              }
            ]

          }

          console.log(JSON.stringify(transaction));
          axios.post(SERVER_NAME + `/rest/zzDamien.pocAPI.createTransaction`,  transaction)
            .then(res => {
              console.log('res.data='+res.data);
              console.dir(res);
              if (res.status == 200) {
                this.setState({ complete: true, reference: res.data.response.reference });
              }
            })

        
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    if (this.props.stripe) {
      this.props.stripe
        .createToken()
        .then((payload) => {

          console.log('[token]', payload.token.id)
           
          var transaction = {
            DateTimeTrans: (new Date()).toISOString(),
            OrderId:  Math.floor(Math.random() * (1000 - 1) + 1),
            OrderComment: "self-ordering",
            Channel: "API_BNE_MGF_APP",
            items: [
              {
                productId: this.props.product.productid,
                QtySold: "1",
                size: "0",
                Gross: this.props.product.retailPrice,
                Nett: this.props.product.retailPrice
              }

            ],
            payments: [
              {
                PaymentName: "Stripe Card",
                PaymentAmount: this.props.product.retailPrice,
                PaymentReference: payload.token.id
              }
            ]

          }

          console.log(JSON.stringify(transaction));
          axios.post(SERVER_NAME + `/rest/zzDamien.pocAPI.createTransaction`,  {transaction})
            .then(res => {
              console.log(res.data);
              if (res.status == 200) {
                this.setState({ complete: true, reference: res.data.response.reference });
              }
            })

        });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    if (this.state.complete) return <h2>Purchase Complete! reference: {this.state.reference}</h2>;
    return (
      <div className={styles['Checkout']}>
        <form onSubmit={this.handleSubmit} >
          <label>
            Card details
          </label>
          <CardElement
            className={styles['StripeElement']}
            {...createOptions(this.props.fontSize)}
          />

          <button>Pay A${this.props.product.retailPrice}</button>
        </form>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);